.app {
  min-height: 100vh;
}

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.moon-container {
  margin: 5rem 0;
  width: 70%;
  max-width: 400px;
  position: relative;
}
.moon-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
  position: absolute;
}

.days-info {
  color: white;
  text-align: center;
}